// FacilityList.js

import React, { useState, useRef } from "react";
import './FacilitiesList.css';
import Navbar from '../components/navbar';
import USAMap from "../components/USAMap";
import FacilityRegistrationForm from './FacilityRegistration';
import ToggleSwitch from './ToggleSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import MapComponent from './MapComponent';
import LottieAnimation from './LottieAnimation';

function FacilityList() {
    // const [showForm, setShowForm] = useState(false);
    
    const [stateClicked, setStateClicked] = useState(false);
    const [time, setTime] = useState("5:00 AM");
    const [radius, setRadius] = useState("<1 mile");
    const [price, setPrice] = useState("$10");
    const [showForm, setShowForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formRef = useRef(null);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [date, setDate] = useState(new Date());
  const address = '1600 Amphitheatre Parkway, Mountain View, CA';
  const closeRef = useRef(null);

  const formattedDate = date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });

    const handleImageClick = () => {
        setShowForm(true);
    };

    const handleCloseForm = () => {
        setShowForm(false);
    };

    const handleFilterChange = (filterValues) => {
        console.log("Filter values:", filterValues);
        // Apply filter logic here based on filterValues (time, radius, price)
    };
    const closeHandler = () =>{
      setShowForm(false);
      setTimeout(() => {
        if (closeRef.current) {
          closeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    }
    const handleSubmit = (event) => {
      event.preventDefault(); // Prevent default form submission
      setIsFormDisabled(true); // Disable the form
      setFormSubmitted(true); // Show success animation
  
      // Close the form after 8 seconds
      setTimeout(() => {
          setShowForm(false);
          setIsFormDisabled(false); // Re-enable the form for future submissions
          setFormSubmitted(false); // Reset the submitted state
      }, 5000);
  };

    return (
        <div className='home-container1'>
            <Navbar rootClassName="navbarroot-class-name"></Navbar>

            {/* Filter Component */}
            <div className="filter-container">
                <div className="filter-item">
                    <label>Time:</label>
                    <select value={time} onChange={(e) => setTime(e.target.value)}>
                        {["5:00 AM", "6:00 AM", "7:00 AM","7:00 AM",
                        "8:00 AM","9:00 AM","10:00 AM","11:00 AM", "12:00 PM",
                      "1:00 PM","2:00 PM","3:00 PM","4:00 PM","5:00 PM",
                    "6:00 PM","7:00 PM","8:00 PM","9:00 PM","10:00 PM",
                  "11:00 PM","12:00 AM"].map((t) => (
                            <option key={t} value={t}>{t}</option>
                        ))}
                    </select>
                </div>
                <div className="filter-item">
                    <label>Radius:</label>
                    <select value={radius} onChange={(e) => setRadius(e.target.value)}>
                        {["<1 mile", "5 miles", "10 miles", "20+ miles"].map((r) => (
                            <option key={r} value={r}>{r}</option>
                        ))}
                    </select>
                </div>
                <div className="filter-item">
                    <label>Price:</label>
                    <select value={price} onChange={(e) => setPrice(e.target.value)}>
                        {["$10", "$15", "$20"].map((p) => (
                            <option key={p} value={p}>{p} per hour</option>
                        ))}
                    </select>
                </div>
      
                <button className="apply-filters-button">
                    Apply
                </button>
            </div>

            <div className="event-content">
                <div className="Appp">
                    <USAMap stateClicked={stateClicked} setStateClicked={setStateClicked} />
                </div>
                
                {/* Conditionally render the list of facilities based on stateClicked */}
                {stateClicked && (
                    <>
                        <h1 className="home-heading10">List of Facilities</h1>
                        <br />
                        <div className="facility-grid">
                            <div className="facility-item">
                                <img 
                                    onClick={handleImageClick} 
                                    src="/SectionImages/sf1.png" 
                                    alt="Nassau Indoor Facility"
                                    className="facility-image"
                                />
                                <p className="facility-message">Nassau Indoor Facility
                                    <br />
                                    Sport Facilities : Badminton
                                </p>
                                <button onClick={handleImageClick} className='update-submit'>Book Now</button>
                            </div>
                            <div className="facility-item">
                                <img 
                                    onClick={handleImageClick} 
                                    src="/SectionImages/sf2.png" 
                                    alt="JFK Tennis Academy"
                                    className="facility-image"
                                />
                                <p className="facility-message">JFK Tennis Academy
                                    <br />
                                    Sport Facilities : Pickleball
                                </p>
                                <button onClick={handleImageClick} className='update-submit'>Book Now</button>
                            </div>
                            <div className="facility-item">
                                <img 
                                    onClick={handleImageClick} 
                                    src="/SectionImages/sf3.png" 
                                    alt="Little Club Indoor"
                                    className="facility-image"
                                />
                                <p className="facility-message">Little Club Indoor
                                    <br />
                                    Sport Facilities : Baseball
                                </p>
                                <button onClick={handleImageClick} className='update-submit'>Book Now</button>
                            </div>
                        </div>
                        {showForm && (
        <div className="event-form-container"  ref={formRef}>
          <div className={`event-form ${formSubmitted ? 'lottie-animation-submitted' : ''}`}>
            {formSubmitted ? (
              <LottieAnimation className="lottie-animation-submitted" />
            ) : (
              <>
                {/* <p><strong>Location:</strong> {getRandomLocation()}</p>
                <p><strong>Time:</strong> {getRandomTime()}</p> */}
                <br />
                <form onSubmit={handleSubmit}>
                  <div className="name-form name-inputs">
                    <label>
                      First Name:
                      <br />
                      <input type="text" name="firstName" className="input-box" disabled={isFormDisabled} />
                    </label>
                    <label>
                      Last Name:
                      <br />
                      <input type="text" name="lastName" className="input-box" disabled={isFormDisabled} />
                    </label>
                  </div>

                  <div className="attendees-team">
                    <label>
                      Number of attendees:
                      <br />
                      <input type="number" name="players" className="input-box" disabled={isFormDisabled} />
                    </label>
                    <label>
                      Team Name:
                      <br />
                      <input type="text" name="team" className="input-box" disabled={isFormDisabled} />
                    </label>
                  </div>

                  <div className="email-toggle">
                    <label>
                      Email:
                      <br />
                      <input type="text" name="email" className="input-box" disabled={isFormDisabled} />
                    </label>
                    <div className="toggle-container">
                      <label>Email reminders?</label>
                      &nbsp; &nbsp;
                      <ToggleSwitch />
                    </div>
                  </div>

                  <div className="event-location">
                    <div className="calendar-info">
                      <FontAwesomeIcon icon={faCalendarCheck} className='cal-icon' />
                      <p className='home-caption1'>{formattedDate}</p> &nbsp;&nbsp;&nbsp;
                    </div>
                    <br />
                    <MapComponent address={address} />
                  </div>

                  <div className="form-buttons">
                    <button class="close-btn" type="button" onClick={closeHandler}>Close</button> &nbsp;&nbsp;&nbsp;
                    <button type="submit" disabled={isFormDisabled}>Book</button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      )}
                    </>
                )}
            </div>

            <footer className="home-footer">
        <div className="home-content7">
          <main className="home-main-content">
            <div className="home-content8">
              <header className="home-main5">
                <div className="home-header24">
                  <span className="home-text52">
                    P L I V E
                  </span>
                </div>
                <div className="home-socials">
                  <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link1"
                  >
                    <img
                      alt="image"
                      src="/Icons/linkedin-200h.png"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link2"
                  >
                    <img
                      alt="image"
                      src="/Icons/instagram-200h.png"
                      className="social"
                    />
                  </a>
                  <a
                    href="https://example.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link3"
                  >
                    <img
                      alt="image"
                      src="/Icons/twitter-200h.png"
                      className="social"
                    />
                  </a>
                </div>
              </header>
              <header className="home-categories">
                <div className="home-category1">
                  <div className="home-header25">
                    <span className="footer-header">Solutions</span>
                  </div>
                  <div className="home-links1">
                    <span className="footer-link">Responsive Web Design</span>
                    <span className="footer-link">Responsive Prototypes</span>
                    <span className="footer-link">Design to Code</span>
                    <span className="footer-link">Static Website Builder</span>
                    <span className="footer-link">
                      Static Website Generator
                    </span>
                  </div>
                </div>
                <div className="home-category2">
                  <div className="home-header26">
                    <span className="footer-header">Company</span>
                  </div>
                  <div className="home-links2">
                    <span className="footer-link">About</span>
                    <span className="footer-link">Team</span>
                    <span className="footer-link">News</span>
                    <span className="footer-link">Partners</span>
                    <span className="footer-link">Careers</span>
                    <span className="footer-link">Press &amp; Media</span>
                  </div>
                </div>
              </header>
            </div>
            <section className="home-copyright1">
              <span className="home-text66">
                © 2024 NYC. Credits will be given.
              </span>
            </section>
          </main>
          <main className="home-subscribe">
            <main className="home-main6">
              <h1 className="home-heading28">Sign up for P L I V E</h1>
              <div className="home-input-field">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="home-textinput input"
                />
                <div className="home-buy4 button">
                  <span className="home-text67">-&gt;</span>
                  <span className="home-text68">
                    <span>Subscribe now</span>
                    <br></br>
                  </span>
                </div>
              </div>
            </main>
            <h1 className="home-notice">
              By signing up for P L I V E you will receive a mails about the exciting events, gears and community updates.
            </h1>
          </main>
          <section className="home-copyright2">
            <span className="home-text71">
              © 2024 NYC. Credits will be given.
            </span>
          </section>
        </div>
      </footer>
        </div>
    );
}

export default FacilityList;
